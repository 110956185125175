<template>
  <!-- FLEX CONTAINER of ITEMS -->
  <div class="_content-media-list">
    <!-- ITEM's flex configuration -->
    <div :class="['_content-media-item', actionOnClick ? 'active-border cursor-pointer' : '']" :key="position" v-for="(media, position) in mediasFormatted">

      <!-- ITEM -->
      <div class="_content-media" @click="clickedMedia(media)">
        <div class="_content-media-icon">
          <div v-if="hasThumbnail(media)" class="_content-media-thumbnail">
            <img class="w-full h-full" :src="getMediaThumbnailUrl(media)"/>
          </div>
          <!-- <feather-icon class="w-full h-full p-4 font-normal" icon="FileIcon" svgClasses="stroke-1 h-full w-full"/> -->
          <div v-else class="w-full h-full flex items-center justify-center">
            <font-awesome-icon
              icon="file"
              class="h-8 w-8 m-auto"
            />
          </div>
        </div>
        <div class="_content-media-text w-64 md:w-96 lg:w-120">
          <div class="_content-media-title break-words">
            {{_.get(media, 'filename')}}
          </div>
          <div class="_content-media-subtitle mt-2">
            {{ getMediaSubtitle(media) }}
          </div>
        </div>
        <div class="_content-media-action">
          <div class="w-full h-full flex items-center justify-center pr-1">
            <font-awesome-icon
              v-tooltip="'Visualizar'"
              @click="() => actionOnClick ? undefined : mediaAction(media, position)"
              :icon="getMediaActionIcon(media)"
              class="h-4 w-4 m-auto hover:text-success"
            />
            <font-awesome-icon
              v-tooltip="'Excluir'"
              v-if="showDelete"
              @click="$emit('deleteMedia', media)"
              icon="times"
              class="h-4 w-4 m-auto hover:text-danger"
            />

          </div>
        </div>
      </div>
      <transition name="fade-top">
      <div class="_content-media-expansion" v-if="expandedMedias.includes(media.id)">
        <content-media-view :media="media"/>
      </div>
      </transition>

    </div>
  </div>
</template>

<script>
import ContentMediaView from './ContentMediaView.vue'

export default {
  components: { ContentMediaView },
  data: () => ({
    hovering: false,
    previewService: null,
    mediasFormatted: [],
    expandedMedias: []
  }),
  props: {
    actionOnClick: {
      type: Boolean,
      default: false
    },
    medias: {
      type: Array,
      default: () => []
    },
    showDelete: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    medias(val) {
      if (this._.isArray(val) && val.length > 0) {
        this.mediasFormatted = val.map((media) => {
          const type = _.get(media, 'type')
          if (type !== 'gdrive') {
            const url = _.get(media, 'data.url')
            const id = _.cloneDeep(media.id)
          }

          if (type === 'gdrive') {
            media.filename = _.get(media, 'data.name')
          }

          return {
            ...media
          }
        })
      }
    }
  },
  methods: {
    clickedMedia(media) {
      if (this.actionOnClick) {
        this.mediaAction(media)
      }
    },
    // Different types of media are located in different places.
    getMediaSource(media) {
      const type = _.get(media, 'type')

      if (['gdrive', 'url'].includes(type)) {
        return _.get(media, 'data.url')
      }

      if (['file'].includes(type)) {
        return _.get(media, 'file_url')
      }
    },
    // Some medias, like file images can be rendered in-app instead of downloading
    fileMediaIsExpandable(media) {
      const filename = _.get(media, 'filename') || ''
      const [name, ext] = filename.split('.')

      const images = ['jfif', 'gif', 'jpeg', 'png', 'jpg', 'bmp', 'webp']

      return [...images].includes(ext)
    },
    // We get the proper Icon for the proper Action
    getMediaActionIcon(media) {
      const type = _.get(media, 'type')

      switch (type) {
      case 'gdrive':
      case 'url':
        return 'link'
      case 'file':
        if (this.fileMediaIsExpandable(media)) {
          return 'eye'
        } else {
          return 'download'
        }
      }
    },
    // This can expand a media element to render it in the page
    expandMedia(media) {
      const remove = this.expandedMedias.findIndex((id) => media.id)
      if (remove !== -1) {
        this.expandedMedias.splice(remove, 1)
      } else {
        this.expandedMedias.push(media.id)
      }
    },
    // Some medias can only be downloaded, whilst others viewed and downloaded.
    mediaAction(media) {
      const openTab = () => {this.$utils.browser.newTab(this.getMediaSource(media))}
      const type = _.get(media, 'type')

      switch (type) {
      case 'gdrive':
      case 'url':
        openTab()
        break
      case 'file':
        if (this.fileMediaIsExpandable(media)) {
          this.expandMedia(media)
        } else {
          openTab()
        }
      }
    },
    getMediaThumbnailUrl(media) {
      const defaultVal = 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Globe_icon.svg/64px-Globe_icon.svg.png'
      const mediaUrl = _.get(media, 'data.url', defaultVal) || defaultVal
      switch (_.get(media, 'type')) {
      case 'file':
        return this._.get(media, 'thumbnail_url', defaultVal) || defaultVal
      case 'url':
        return `${process.env.VUE_APP_API_BASE_URL}/api/v1/preview?u=${encodeURIComponent(mediaUrl)}`
      case 'gdrive':
        return _.get(media, 'data.iconUrl')
      default:
      }
    },
    getPreview(url, mediaId) {

      let data = {key: process.env.VUE_APP_LINKPREVIEW_API_KEY, q: url}

      /**
       * Temporary linkpreview api usage, we are going to change this to spatie browsershot
       * on our serverside code, safer and nicer to maintain.
       */
      fetch('https://api.linkpreview.net', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
      }).then(res => res.json()).then(response => {
        if (_.has(response, 'title') && _.has(response, 'image')) {
          const position = this.mediasFormatted.findIndex((media) => media.id === mediaId)
          if (position !== -1) {
            /**
             * The filename injected isnt really a file name, but the page title actually.
             */
            this.mediasFormatted[position].filename = _.get(response, 'title', url)
            this.mediasFormatted[position].thumbnail_url = _.get(response, 'image', url)
          }
        }
      })
    },
    getMediaSubtitle(media) {
      switch (this._.get(media, 'type')) {
      case 'gdrive':
        return this.$t('google-drive')
      case 'file':
        return this.$t('arquivo-enviado')
      case 'url':
        return this._.get(media, 'data.url')
      default:
      }
    },
    hasThumbnail(media) {
      const type = _.get(media, 'type')
      const hasThumbUrl = _.get(media, 'thumbnail_url')
      const [filename, extension] = (this._.get(media, 'filename') || '').split('.')

      // Extension/Types that our backend, through Spatie Media, can generate thumbnails.
      const hasThumbnail = [
        'jpeg', 'jpg', 'pdf', 'png', 'svg', 'webp', 'mp4', 'mov'
      ]
      const extensionHasThumbnail = hasThumbnail.includes(extension)

      return (extensionHasThumbnail && hasThumbUrl) || type === 'url' || type === 'gdrive'
    }
  }
}
</script>

<style lang="scss">
div._content-media-list {
  @apply flex;
  @apply flex-col;
  @apply gap-2;
  @apply pb-6;
}

._content-media-item {
  border-width: 1px;
  border-radius: 0.375rem;
}

._content-media-text {
  border-width: 0px 0px 0px 2px;
  padding: 0.5rem;
}

._content-media-expansion {
  border-width: 1px 0px 0px 0px;
  & img {
    max-height: 500px;
  }
}

._content-media-item,
._content-media-text,
._content-media-expansion
{
  min-height: 50px;
  border-style: solid;
  border-color: theme('colors.grey-light');
}

._content-media-item,
._content-media-text,
._content-media-expansion {
  &.active-border:hover {
    border-color: $primary;
  }
}

._content-media-icon {
  height: 5rem;
  min-width: 5rem;
}
._content-media-action {
  @apply ml-auto;
  min-width: 3rem;

  & > div.flex > * {
    @apply cursor-pointer;
  }
}

._content-media-title {
  @apply truncate;
}

._content-media-title, ._content-media-subtitle {
  @apply select-none truncate;

  word-wrap: break-word !important;
  overflow-wrap: break-word !important;

  display: block;
  height: 50%;
}

._content-media {
  @apply flex;
  @apply flex-row;
  width: 100%;
}

._content-media-thumbnail {
  & img {
    max-width: 5rem;
    max-height: 5rem;
    object-fit: contain;
  }
}
</style>
