<template>
  <div class="p-2">
    <div v-if="isFile && fileIsImage && fileSource">
      <div class="w-full flex justify-center items-center">
        <img :src="fileSource"/>
      </div>
    </div>
    <div v-if="showDownload" class="pt-2 w-full flex justify-end">
      <vs-button icon="file_download" @click="downloadMedia"> {{ $t('baixar') }} </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getExtension(filename) {
      if (_.isString(filename)) {
        const [name, extension] = filename.split('.')
        return extension
      }
    },
    downloadMedia() {
      this.$utils.browser.newTab(this.fileSource)
    }
  },
  computed: {
    type() {
      return _.get(this.media, 'type')
    },
    isLink() {
      return this.type === 'url'
    },
    isGDrive() {
      return this.type === 'gdrive'
    },
    isFile() {
      return this.type === 'file'
    },
    fileIsImage() {
      const filename = _.get(this.media, 'filename')
      const extension = this.getExtension(filename)
      return ['jfif', 'gif', 'jpeg', 'png', 'jpg', 'bmp', 'webp'].includes(extension)
    },
    filename() {
      return _.get(this.media, 'filename') 
    },
    fileSource() {
      return _.get(this.media, 'file_url')
    },
    showDownload() {
      return true
    }
  },
  props: {
    media: {
      default: null,
      type: Object
    }
  }
}
</script>

<style>

</style>