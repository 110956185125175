var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-2" }, [
    _vm.isFile && _vm.fileIsImage && _vm.fileSource
      ? _c("div", [
          _c(
            "div",
            { staticClass: "w-full flex justify-center items-center" },
            [_c("img", { attrs: { src: _vm.fileSource } })]
          ),
        ])
      : _vm._e(),
    _vm.showDownload
      ? _c(
          "div",
          { staticClass: "pt-2 w-full flex justify-end" },
          [
            _c(
              "vs-button",
              {
                attrs: { icon: "file_download" },
                on: { click: _vm.downloadMedia },
              },
              [_vm._v(" " + _vm._s(_vm.$t("baixar")) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }